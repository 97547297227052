import Gateway from '@nsoft/seven-gravity-gateway/slave';
import store from '@/store';
import { merge } from 'lodash';
import gatewayDataParser from './gatewayDataParser';

let gateway = {};

const setListeners = () => {
  gateway.on('User.AuthorizationChanged', (message) => {
    store.dispatch('setAuth', gatewayDataParser.parseAuthData(message.data));
  });
};

export default {
  sendMessage(action, data) {
    gateway.sendMessage({
      action,
      data,
    });
  },

  init() {
    return new Promise((resolve) => {
      gateway = Gateway({
        slaveId: process.env.VUE_APP_SLAVE_ID,
        load: (message) => {
          merge(window.NSoft, gatewayDataParser.parseLoadData(message.data));
          this.sendMessage('Slave.Loaded');
          resolve();
        },
        eventListeners: {
          scroll: true,
        },
        allowedOrigins: window.NSoft.config.providerHomePages,
        debug: false,
      });

      setListeners();
    });
  },
};
