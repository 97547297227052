export default {
  parseAuthData({ auth }) {
    return {
      token: auth.token,
      user: this.parseUserData(auth.user),
    };
  },

  parseUserData(data) {
    return {
      settings: {
        oddType: data.settings.oddType,
      },
      profile: {
        language: data.profile && data.profile.language ? data.profile.language : null,
      },
    };
  },

  parseLoadData(data) {
    const auth = {
      token: data.auth.token,
      user: this.parseUserData(data.user),
    };
    const tenant = {
      uuid: data.tenant.uuid,
    };
    return { auth, tenant };
  },
};
